<template>
    <v-tooltip v-if="forDialog === false" bottom>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="getQueryResults(qid)" icon :loading="queryPreviewLoading" class="font-weight-bold" color="primary">
                <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
        </template>
        <span>Preview results</span>
    </v-tooltip>
    <v-btn color="secondary" text v-else @click="getQueryResults(qid)">preview results</v-btn>
</template>
<script>
export default {
    name: 'QueryViewResults',
    props: {
        qid: String,
        sql: String,
        forDialog: Boolean
    },
    data() {
        return {
            queryPreviewLoading: false
        }
    },
    methods: {
        getQueryResults: function(qid) {
            if (this.$props.forDialog === true) {
                this.$emit('closeDialog', { value: true })
            }
            this.$data.queryPreviewLoading = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Retrieving data..',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.$axios
                .get(`/queries/${qid}/results`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Results are ready!',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                            data: response.data,
                            sql: this.$props.sql,
                            qid: this.$props.qid
                        })
                        this.$emit('queryResultsSuccess', { value: true })
                    } else {
                        this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                            data: [],
                            sql: this.$props.sql,
                            qid: this.$props.qid
                        })
                        this.$emit('queryResultsSuccess', { value: true })
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'The query returned no results',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'warning'
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to retrieve data, please try again later.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.queryPreviewLoading = false
                })
        }
    }
}
</script>
